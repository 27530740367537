import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { NewBrandLP } from "../../components/NewBrandLP"
import Layout from "../../components/Layout/Layout"
import { Helmet } from "react-helmet"
import "../../styles/lp_global.scss"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
export const query = graphql`
  query {
    brandLogoBig: file(
      relativePath: {
        eq: "landing-page-new/fr/top-menu-section/brand-logo.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 42
          quality: 100
          placeholder: NONE
        )
      }
    }
    brandLogoSmall: file(
      relativePath: {
        eq: "landing-page-new/fr/top-menu-section/brand-logo.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 32
          quality: 100
          placeholder: NONE
        )
      }
    }
    pageHeadMediaDesktop: file(
      relativePath: {
        eq: "landing-page-new/fr/page-head-section/desktop-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 445)
      }
    }
    pageHeadMediaMobile: file(
      relativePath: {
        eq: "landing-page-new/fr/page-head-section/mobile-media.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 690)
      }
    }
    partnersLogo1: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo2: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo3: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo4: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo5: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo6: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    partnersLogo7: file(
      relativePath: { eq: "landing-page-new/fr/partners-section/frame-7.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          height: 106
          quality: 100
          placeholder: NONE
        )
      }
    }
    videoPlaceholder: file(
      relativePath: {
        eq: "landing-page-new/fr/demo-video-section/preview-image.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1060
          height: 544
          quality: 100
        )
      }
    }
    presentationItem1media: file(
      relativePath: {
        eq: "landing-page-new/fr/presentation-items-section/media-1.svg"
      }
    ) {
      publicURL
    }
    presentationItem2media: file(
      relativePath: {
        eq: "landing-page-new/fr/presentation-items-section/media-2.svg"
      }
    ) {
      publicURL
    }
    presentationItem3media: file(
      relativePath: {
        eq: "landing-page-new/fr/presentation-items-section/media-3.svg"
      }
    ) {
      publicURL
    }
    videoTestimonialAuthorThumb: file(
      relativePath: {
        eq: "landing-page-new/fr/video-testimonial-section/author-thumb.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 60)
      }
    }
    gettingStartedMedia: file(
      relativePath: {
        eq: "landing-page-new/fr/getting-started-section/getting-started.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

const LogicielGestionFinanciere = props => {
  useScrollTracking()
  usePageTracking()

  const videoSectionHtmlId = "watchVideo"
  const formSectionHtmlId = "demoForm"

  // extract query data
  const {
    videoPlaceholder: { childImageSharp: previewImage },
    partnersLogo1,
    partnersLogo2,
    partnersLogo3,
    partnersLogo4,
    partnersLogo5,
    partnersLogo6,
    partnersLogo7,
    presentationItem1media,
    presentationItem2media,
    presentationItem3media,
    videoTestimonialAuthorThumb,
    gettingStartedMedia,
    brandLogoBig: { childImageSharp: logoBig },
    brandLogoSmall: { childImageSharp: logoSmall },
    pageHeadMediaDesktop,
    pageHeadMediaMobile
  } = props.data

  // top menu section (header)
  const topMenuSection = {
    brandLogo: {
      ...logoBig,
      description: "Agicap logo",
      small: logoSmall
    },
    brandLogoTargetUrl: "https://agicap.com/fr/",
    rightMenuItems: [
      {
        id: "e48fc062-2708-55f2-b1be-1f861526eb70",
        title: "Essayer gratuitement ",
        subtitle: null,
        targetUrl: `#${formSectionHtmlId}`,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: "button",
        backgroundColor: null,
        eventAction: "click_request_trial",
        eventLabel: "{{page_perimeter}}",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: null
      }
    ],
    locale: "fr-FR",
    defaultLocale: "fr-FR"
  }

  // page head section
  const pageHeadSection = {
    desktopMedia: pageHeadMediaDesktop,
    mobileMedia: pageHeadMediaMobile,
    mediaLinkTargetUrl: `#${videoSectionHtmlId}`,
    mediaLinkLabel: "Voir la vidéo démo",
    title:
      'Le logiciel pour piloter sereinement votre <span class="highlight">trésorerie</span>',
    subtitle:
      "Gagnez en visibilité sur votre trésorerie<br class='d-none d-lg-block' /> et améliorez vos décisions financières.",
    body: "Demandez une démo, un expert vous rappellera<br class='d-none d-lg-block' /> pour vous présenter la plateforme Agicap :",
    ctaButtons: [
      {
        title: "Demander une démo personnalisée",
        targetUrl: `#${formSectionHtmlId}`,
        eventAction: "click_request_demo",
        eventLabel: "{{page_perimeter}}",
        displayStyle: "primaryButton"
      },
      {
        title: "Voir la vidéo démo",
        targetUrl: `#${videoSectionHtmlId}`,
        eventAction: "click_request_video_demo",
        eventLabel: "{{page_perimeter}}",
        displayStyle: "secondaryButton"
      }
    ],
    ctaFooter: "<ul><li>Essai gratuit</li><li>Sans engagement</li></ul>",
    hubspotForm: {
      formId: "70cd2d03-a226-4c4a-81a5-73f7ac673520",
      portalId: "2856475",
      locale: "fr-FR",
      eventAction: "LeadGen Form Submission"
    },
    features: [
      { title: "N°1", description: "en Europe" },
      { title: "+ 6 000", description: "Clients" },
      { title: "+ 300", description: "Intégrations<br/>compatibles" },
      { title: "4,6/5", description: "Satisfaction<br/>client" }
    ],
    locale: "fr-FR",
    defaultLocale: "fr-FR"
  }

  // partners section
  const partnersSection = {
    title: 'Ils nous font <span class="highlight">confiance</span>',
    logos: [
      partnersLogo1,
      partnersLogo2,
      partnersLogo3,
      partnersLogo4,
      partnersLogo5,
      partnersLogo6,
      partnersLogo7
    ],
    features: [
      {
        icon: "clock",
        title: "Gain de temps",
        description: "Gagnez du temps sur votre suivi quotidien"
      },
      {
        icon: "ok",
        title: "Suivi en temps réel",
        description:
          "Gagnez en visibilité sur la lecture et l’analyse de vos flux"
      },
      {
        icon: "target",
        title: "100% Fiable",
        description:
          "Gagnez en fiabilité sur le contenu de votre tableau de suivi"
      }
    ]
  }

  // demo youtubevideo section
  const demoVideoSection = {
    title:
      'Découvrez notre logiciel de gestion de trésorerie en <span class="highlight">3 min</span>',
    hubspotForm: {
      portalId: "2856475",
      formId: "0d2b1cb6-113e-4dbd-b841-e04f787559ac",
      locale: "fr-FR"
    },
    youtubeVideo: {
      youtubeVideoId: "h3x7KwiKOLE",
      previewImage
    },
    htmlId: videoSectionHtmlId
  }

  // presentation items section
  const presentationItemsSection = {
    sectionTitle: null,
    presentationItems: [
      {
        title: "Gagnez en visibilité sur votre trésorerie",
        media: presentationItem1media,
        body: `<ul><li>Factures décalées, encaissements échelonnés, de nombreux fournisseurs ou clients... ll est difficile d'avoir une visibilité de sa trésorerie sur le long terme.</li><li>Avec le logiciel de gestion de trésorerie Agicap, anticipez votre trésorerie sur les 12 prochains mois pour prendre les bonnes décisions et sécuriser vos besoins de financement.</li></ul>`,
        ctaLabel: "Démarrer votre essai gratuit",
        ctaTargetUrl: `#${formSectionHtmlId}`
      },
      {
        title: "Automatisez le suivi de votre trésorerie",
        media: presentationItem2media,
        body: `<ul><li>Le suivi sur Excel est souvent chronophage, compliqué et synonyme de perte de temps.</li><li>Plus besoin de rentrer manuellement et de catégoriser ses encaissements et ses factures, avec le logiciel de trésorerie Agicap : tout est automatisé ! Vous accédez à un suivi mis à jour en temps réel de votre trésorerie.</li><li>Gagnez du temps et concentrez-vous sur l'analyse et la prise de décisions !</li></ul>`,
        ctaLabel: "Démarrer votre essai gratuit",
        ctaTargetUrl: `#${formSectionHtmlId}`
      },
      {
        title: "Planifiez et adaptez vos prévisions en tout simplicité",
        media: presentationItem3media,
        body: `<ul><li>Fixez, suivez et ajustez vos objectifs et budgets prévisionnels quotidiennement et en quelques clics grâce à Agicap.</li><li>Simulez en quelques clics l’impact sur votre trésorerie de potentiels investissements, embauches, baisses de chiffre d’affaire, retards de paiements ou autres.</li><li>Prenez les bonnes décisions pour votre trésorerie sur la base de scénarios prévisionnels fiables.</li></ul>`,
        ctaLabel: "Démarrer votre essai gratuit",
        ctaTargetUrl: `#${formSectionHtmlId}`
      }
    ]
  }

  // global demo form section
  const demoFormSection = {
    title: 'Demander une démo <span class="highlight">gratuite</span>',
    hubspotForm: {
      portalId: "2856475",
      // formId: "70a52e88-8842-49e6-83f1-30f669312270",
      formId: "cfde4aa3-929b-4dca-800b-884bd613cb0b",
      eventAction: "LeadGen Form Submission",
      eventLabel: "d4d53fba-3e14-4dbe-8350-45b414fe5b4e-DemoForm",
      locale: "fr-FR"
    },
    htmlId: formSectionHtmlId
  }

  // boxed features
  const boxedFeatures = {
    sectionTitle: null,
    features: []
  }

  // features section
  const featuresSection = {
    features: [
      {
        title: "Sécurité",
        description:
          "Toutes les données stockées sont protégées par des algorithmes et conformes aux réglementations européennes.",
        icon: "security"
      },
      {
        title: "Mise en place rapide",
        description:
          "Pas besoin de carte de crédit pour démarrer. Testez Agicap en moins de 20 minutes.",
        icon: "flash"
      },
      {
        title: "À votre écoute",
        description:
          "Notre équipe de spécialistes vous accompagne dans la mise en place et la formation sur le logiciel.",
        icon: "chat"
      }
    ]
  }

  // video testimonial section
  const videoTestimonialSection = {
    youtubeVideo: {
      youtubeVideoId: "U8BUM4R3mxg",
      previewImage: null
    },
    testimonial: {
      body: "Nous consolidons l'ensemble des comptes bancaires et ça nous permet d’avoir une vision d’ensemble de la trésorerie du groupe et d’orienter au mieux les flux de trésorerie selon les besoins de chaque entité.",
      author: "Joëlle Guirard",
      position: "DAF de Wyz Group",
      authorThumb: videoTestimonialAuthorThumb
    }
  }

  const imageFeatures = {
    features: []
  }

  // getting started section
  const gettingStartedSection = {
    media: gettingStartedMedia,
    title: "Essayez avec vos<br/>propres données !",
    ctaLabel: "Essayez gratuitement",
    ctaTargetUrl: `#${formSectionHtmlId}`
  }

  // footer section
  const footerSection = {
    locale: "fr-FR",
    defaultLocale: "fr-FR",
    headerText:
      'Restons en contact par téléphone <a href="tel:0481681090">04 81 68 10 90</a>',
    menuItems: [
      {
        id: "9f85ecc2-b2e4-5f88-aca0-3f5e5d16a1d3",
        title: "PRODUITS",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "produits",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "ad37731c-5c7d-57f1-9a63-edb55815186e",
            title: "Gérer sa trésorerie",
            subtitle: null,
            targetUrl: "/fr/produits/gestion-de-tresorerie",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "produits-gerer-sa-tresorerie",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "e2c73c92-de97-588d-b033-25883d03395a",
            title: "Payer ses fournisseurs",
            subtitle: null,
            targetUrl: "/fr/produits/payer-fournisseurs/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "produits-payer-ses-fournisseurs",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "fbfe63c4-9534-591d-8b0c-2314457f222a",
            title: "Se faire payer",
            subtitle: null,
            targetUrl: "/fr/produits/se-faire-payer",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "produits-se-faire-payer",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "9411f5a5-c468-5ae9-aa2b-d783bc4d57ed",
        title: "FONCTIONNALITES",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "features",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "2bab0f46-24b6-512e-b198-25d38ef68db7",
            title: "Suivi de trésorerie",
            subtitle: null,
            targetUrl: "/fr/fonctionnalites/suivi-de-tresorerie/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "features-suivi-de-tresorerie",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "971b40d4-7c95-5734-81c6-31d5902762ac",
            title: "Prévisionnel de trésorerie",
            subtitle: null,
            targetUrl: "/fr/fonctionnalites/previsionnel-de-tresorerie/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "features-previsionnel-de-tresorerie",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "7dee197c-4edf-50bf-a8c6-cff249396b3b",
            title: "Intégrations",
            subtitle: null,
            targetUrl: "/fr/fonctionnalites/integrations/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "features-integrations",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "590f8070-f721-5287-966a-9a06714995f4",
            title: "Vision consolidée",
            subtitle: null,
            targetUrl: "/fr/fonctionnalites/consolidation/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "features-vision-consolidee",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "9d3fe9b4-4202-5038-ade5-0ec8fa068e9a",
            title: "Reporting",
            subtitle: null,
            targetUrl: "/fr/fonctionnalites/reporting/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "features-reporting",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "30668fde-a677-5f5d-9d43-38af605c8427",
            title: "Application mobile",
            subtitle: null,
            targetUrl: "/fr/fonctionnalites/application-mobile/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "features-application-mobile",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "f49c81c7-3fe0-54e7-aaf2-ae820a3a6b5a",
        title: "RESSOURCES",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "ressources",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "fd7cd346-b526-58be-a2a5-192d5b376d90",
            title: "Centre de ressources",
            subtitle: null,
            targetUrl: "/fr/ressources/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "ressources-centre-de-ressources",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "7ae1d673-93bc-5995-9b34-1b2567cf5cf6",
            title: "Blog",
            subtitle: null,
            targetUrl: "/fr/blog/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "ressources-blog",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "e8ed8742-0b5c-5b8f-b26b-88e16d372a3d",
            title: "Cash Academy",
            subtitle: null,
            targetUrl: "/fr/inscription-cash-academy/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "ressources-cash-academy",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "561d9184-5dd3-5e63-8698-b367bbfec4d9",
        title: "LEGAL",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "legal",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "5fca65b7-f4c7-57bd-87a7-33ddc30503ed",
            title: "CGU",
            subtitle: null,
            targetUrl: "/fr/cguv/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-cgu",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "bb170b67-9982-54ee-95c0-11eb8670a743",
            title: "Confidentialité",
            subtitle: null,
            targetUrl: "/fr/politique-de-confidentialite/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-privacy",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "5235dc4d-318d-5f2d-93ac-d774d811794a",
            title: "Mentions légales",
            subtitle: null,
            targetUrl: "/fr/mentions-legales/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "legal-terms-of-use",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      },
      {
        id: "2317233a-78db-50a3-b682-2f41dfacd254",
        title: "A PROPOS",
        subtitle: null,
        targetUrl: null,
        relAttribute: null,
        targetAttribute: null,
        displayStyle: null,
        backgroundColor: null,
        eventAction: "click_footer",
        eventLabel: "aboutus",
        icon: null,
        primaryColumnMenuItemIcon: null,
        ctaImage: null,
        ctaTitle: null,
        ctaLink: null,
        childMenuItems: [
          {
            id: "a8237401-3619-5e1f-896b-63eca8f4eedd",
            title: "Contact",
            subtitle: null,
            targetUrl: "/fr/contactez-nous/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-contact",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "a0e231db-41ad-5d7c-b837-543a615f190b",
            title: "Nos partenaires ",
            subtitle: null,
            targetUrl: "/fr/devenez-partenaires/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: null,
            eventLabel: null,
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "2fca7f18-3855-5740-926a-76df8f074cdb",
            title: "On recrute",
            subtitle: null,
            targetUrl: "https://career.agicap.com/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-career",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "706cca5c-6a18-5493-a7fa-eaeb280a99da",
            title: "Presse",
            subtitle: null,
            targetUrl: "/fr/presse/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-press",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "7d422970-1fc4-5d5b-9ac7-83cf87312d0e",
            title: "Sécurité",
            subtitle: null,
            targetUrl: "/fr/securite/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-security",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          },
          {
            id: "bbdbddb6-b61f-5167-8cc6-145dedec36dc",
            title: "Plan de site",
            subtitle: null,
            targetUrl: "/fr/plan-du-site/",
            relAttribute: null,
            targetAttribute: null,
            displayStyle: null,
            backgroundColor: null,
            eventAction: "click_footer",
            eventLabel: "aboutus-sitemap",
            icon: null,
            primaryColumnMenuItemIcon: null,
            ctaImage: null,
            ctaTitle: null,
            ctaLink: null,
            childMenuItems: null
          }
        ]
      }
    ]
  }

  const pageData = {
    topMenuSection,
    pageHeadSection,
    partnersSection,
    demoVideoSection,
    presentationItemsSection,
    boxedFeatures,
    demoFormSection,
    featuresSection,
    videoTestimonialSection,
    imageFeatures,
    gettingStartedSection,
    footerSection
  }

  const locale = "fr-FR"
  const trackingData = getPageData(
    locale,
    {
      pagePerimeter: "landing_page",
      pagePublication: "",
      pageFeature: "cashflow_monitoring"
    },
    "n-logiciel-gestion-financiere",
    props.path
  )

  return (
    <Layout>
      <Helmet>
        <title>Logiciel de gestion financière pour PME/ETI</title>
        <meta
          name="description"
          content="Découvrez Agicap, le logiciel de gestion financière pour suivre vos performances de trésorerie en un clic ! Gagnez du temps et pilotez votre entreprise sereinement"
        />
        <meta name="robots" content="noindex nofollow" />
        <script type="text/javascript">{`${trackingData}`}</script>
      </Helmet>
      <NewBrandLP pageData={pageData} />
    </Layout>
  )
}

LogicielGestionFinanciere.propTypes = {
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
}
export default LogicielGestionFinanciere
